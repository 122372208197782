import React from 'react';
import './App.css';
// import './all.css';
import AIMLProgram from './AiProgram';
import Header from './Header';
import Landing from './Landing';

function App() {
  return (
    <>
       <Header/>
        <Landing/>

    </>
  );
}

export default App;
