import React from 'react';
import './index.css';
import { ReactComponent as Logo } from '../assets/abstractGeometricShapesTransparent.svg';
import { FaLinkedin } from 'react-icons/fa';

const Landing = () => {
	return (
		<div className='site' id='page'>
			<main id='main-content' className='main-content'>
				<div className='section'>
					<div className='section-left'>
						<h1 className='heading-main'>
							Transforming Dreams into Tech Careers
						</h1>
						<p className='description'>
							Unlock your potential with industry-leading training
							and real-world experience.
						</p>
						<a className='button-primary' href='#'>
							Start Your Journey
						</a>
					</div>
					<div className='section-right'>
						<Logo width={500} height={800} />
					</div>
				</div>

				<div className='section-column section-secondary'>
					<div className='section-left'>
						<h3 className='heading-secondary'>Our Programs</h3>

						<p className='description'>
							At Transformer Labs, we offer comprehensive training
							programs across the core domains of modern
							technology. Our curriculum-driven approach equips
							students with practical, industry-relevant skills to
							jumpstart their careers in AI/ML, backend
							development, and frontend engineering. Through
							hands-on projects and real-world case studies,
							students gain valuable experience to apply in their
							future roles.
						</p>
						<a className='button-secondary' href='#'>
							Learn More
						</a>
					</div>
					<div className=' program'>
						<div className='program-info'>
							<Logo width={300} height={300} />

							<h4 className='program-title'>AI & ML Program</h4>
							<p className='program-description'>
								Our comprehensive AI/ML program trains in the
								latest techniques and tools for developing and
								deploying machine learning models. Gain hands-on
								experience in areas such as neural networks,
								computer vision, natural language processing,
								and MLOps.
							</p>
							<a className='button-secondary' href='#'>
								Learn More
							</a>
						</div>
						<div className='program-info'>
							<Logo width={300} height={300} />

							<h4 className='program-title'>Backend Program </h4>
							<p className='program-description'>
								Our backend development program covers
								server-side technologies, APIs, databases, and
								architectural patterns to build scalable and
								secure application backends. Students will gain
								expertise in creating robust backend systems.
							</p>
							<a className='button-secondary' href='#'>
								Learn More
							</a>
						</div>
						<div className='program-info'>
							<Logo width={300} height={300} />

							<h4 className='program-title'>Frontend Program</h4>
							<p className='program-description'>
								Our frontend program equips students with skills
								in modern JavaScript frameworks, responsive
								design principles, UI/UX best practices, and
								cutting-edge web development approaches to
								create engaging user experiences.
							</p>
							<a className='button-secondary' href='#'>
								Learn More
							</a>
						</div>
					</div>
				</div>

				<div className='section-column section-secondary'>
					<h3 className='heading-secondary'>
						From Learning to Leading: Your Tech Journey
					</h3>
					<Logo width={900} height={300} />
				</div>
				<div className='section-column section-secondary'>
					<h3 className='heading-secondary'>
						Partner with Transformer Labs to Access Top Talent
					</h3>
					<p className='description'>
						Transform your hiring journey with Transformer Labs,
						where top-tier tech talent meets innovation. Our
						training programs don’t just teach; they prepare
						industry-ready professionals for immediate impact in
						your projects. Dive into a talent pool rich with
						expertise in AI/ML, Backend, and Frontend development,
						and more. With our hands-on approach, candidates are
						groomed to seamlessly integrate into your business,
						embodying the skills and ethos you value. Partner with
						us for a streamlined path to securing the tech leaders
						of tomorrow, today.
					</p>
					<Logo width={900} height={300} />
					<a className='button-primary' href='#'>
						Partner with Us Today
					</a>
				</div>

				<div className='section-column section-secondary'>
					<div className='section'>
						<div className=''>
							<h1 className='heading-secondary'>
								Our Inspiring Journey: Pioneering Excellence in
								Tech Education
							</h1>
							<p className='description'>
								From our inception, Transformer Labs has been at
								the forefront of tech education, driven by a
								passion to empower and innovate. Our journey is
								a testament to the relentless pursuit of
								excellence and the transformative power of
								education.
							</p>
							<a className='button-primary' href='#'>
								Learn More
							</a>
						</div>
						<div className=''>
							<Logo width={300} height={300} />
						</div>
					</div>
					<div className='section-column'>
						<h3 className='heading-secondary'>
							Foundation & Mission
						</h3>
						<p>
							Founded on the principles of innovation and
							accessibility, our mission is to democratize tech
							education, making it inclusive and impactful. We
							started with a vision to create a learning space
							where anyone, regardless of background, could
							acquire cutting-edge tech skills and thrive in the
							digital age.
						</p>

						<h3 className='heading-secondary'>
							Growth & Milestones
						</h3>
						<p>
							Our story unfolds through persistent growth and
							adaptation, expanding our courses to align with the
							tech industry’s dynamics. We’ve developed
							specialized training and formed partnerships with
							top firms, all driven by our dedication to
							delivering superior education and career prospects.
						</p>
						<h3 className='heading-secondary'>Impact & Vision</h3>
						<p>
							While our journey is still unfolding, we are already
							witnessing the transformative impact of our work.
							Through our innovative teaching methods and
							unwavering support, we are empowering a new
							generation of tech professionals who are poised to
							drive innovation and shape the future of the
							industry.
						</p>
					</div>
					<div className='buttons-container'>
						<a className='button-primary' href='#'>
							Hire Top Talents
						</a>

						<a className='button-primary' href='#'>
							Join Our Programs
						</a>
					</div>
				</div>
			</main>

			<footer className='site-footer'>
                <div className='section'>
                <p>&copy; Transformer Labs 2024</p>
                <a href="https://www.linkedin.com/company/transformer-labs/" target="_blank" rel="noopener noreferrer" style={{ color: '#0e76a8', fontSize: '24px' }}>
                    <FaLinkedin color='white' />
                </a>
                </div>
				
			</footer>
		</div>
	);
};

export default Landing;

