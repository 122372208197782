import React from 'react';
import './Header.css';  // Assuming you keep CSS in a separate file

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src="headerLogo.svg" alt="Transformer Labs" />
      </div>
      <nav className="nav-menu">
        <ul>
          <li><a href="#programs">Programs</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
      <div className="cta-buttons">
        <a href="#apply" className="btn primary">Become a Student</a>
        <a href="#join" className="btn secondary">Join Us</a>
      </div>
    </header>
  );
};

export default Header;
